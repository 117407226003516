@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ************************************************************************** */
/* Navbar */

.App-navbar {
  font-size: 1em;
  width: 100%;
	height: 58px;
	background: linear-gradient(90deg, #B9DCF2, #FFC7F9); 
	text-align: center;
	position: relative;
	position: fixed;
	top: 0;
  transition: (all .5s ease);
  z-index : 1;
}

.App-resume {
  position: absolute; 
  top: 10px; 
  right: 35px; 
  text-align: right;
  border-radius: 5px;
}

.App-resume:hover {
  background-color: white;
  opacity: .6;
}

.App-resume-border {
  box-sizing: border-box;
  width: 121px;
  height: 38px;
  border: 1px solid #000000;
  border-radius: 5px; 
  text-align: center;
}

resume {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  top: 10px;
  right: 28px;
  position: absolute;
  color: #000;
}

.App-contacts {
  position: absolute; 
  top: 29px;  
  width: 150px;
  height: 0;
  text-align: left;
  float: left;
  border: 1px solid #000000;
}

.App-icons {
  justify-content: center;
  left: 160px;
  bottom: 10px;
  position: relative;
  vertical-align: center;
  float: left;
}

.App-icons img {
  height: 20px;
  margin: 0 14px;
}

/* ************************************************************************** */

/* Header Welcome CSS Formatting */

.App-body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.App-section {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    overflow: hidden;
}

.App-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #B9DCF2, #FFC7F9);
    border-radius: 0 0 100% 100%/ 0 0 100% 100%;
    transform: scaleX(1.5);
}

.App-content
{
    position: absolute;
    z-index: 1;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    max-width: center;
    padding-left: 250px;
    top: 125px;
}

.App-profile-pic {
  float: right;
  padding-left: 100px;
  padding-top: 20px;
  z-index: 3;

}

.App-profile-pic img {
  height: 300px;
  border-radius: 170px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.App-header-text {
  float: left;
  z-index: 2;
  padding-top: 100px;
  padding-right: 2px;
}

h1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    text-align: left;
    padding: 0;
    color: #000;
    font-size: 1em;
}

h2 {
    font-family: 'Inter';
    margin: 0;
    padding: 0;
    color: black;
    font-size: 3em;
}

p {
  font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    text-align: left;
    padding: 0;
    color: #000;
    font-size: 1em;
    padding-bottom: 200px;
}

/* .App-contact {
    text-align: left;
    width: 20%;
    height: 158px;
    padding-top: 1370px;
    padding-left: 770px;
    position: fixed;
    top:0;

}


.social-icons {
  width: 250px;
  text-align: center;
  position: absolute;
  margin-left: 40px;
}

.social-icons img {
  height: 20px;
  margin: 0 14px;
  transform: rotate(90deg);
}

.social-icons::after {
  content: '';
  width: 150px;
  height: 1px;
  background: #000;
  position: absolute;
  left: -160px;
  top: 10px;
}

.social-icons:hover {
  color: linear-gradient(90deg, #B9DCF2, #FFC7F9);
} */


/* ************************************************************************** */

/* Projects 2.0 */

.App-projects {
  text-align: left;
  padding-left: 150px;
  padding-top: 20px;
  flex-wrap: wrap;
  flex-basis: 100%;
  height: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: relative;
}

.project-row {
  flex-grow: 1;
  justify-content: center;
  
}

.projects-right {
  padding-right: 12%;
  padding-top: 20px;
  padding-bottom: 22px;
  float: right;
}


.projects-backCard-right {
  text-align: left;
  transform: rotate(180deg);
  width: 450px;
  height: 250px;
  border-radius: 15px;
  z-index: 1;
  padding-left: 25px;
  padding-top: 15px;
  background-color: #D8C8F4;
}

.projects-frontCard-right {
  text-align: left;
  width: 430px;
  height: 210px;
  padding-left: 25px;
  padding-right: 10px;
  background: #FFFFFF;
  transform: rotate(180deg);
  border-radius: 15px;
  overflow: hidden;
  z-index: 2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.projects-left {
  float: left;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 12%;
}

.projects-backCard-left {
  text-align: left;
  width: 450px;
  height: 227px;
  border-radius: 15px;
  z-index: 1;
  padding-left: 25px;
  padding-top: 38px;
  background-color: #D8C8F4;
}

.projects-frontCard-left {
  text-align: left;
  width: 430px;
  height: 210px;
  padding-left: 25px;
  padding-right: 10px;
  background: #FFFFFF;
  border-radius: 15px;
  overflow: hidden;
  z-index: 2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.App-project-github {
  padding-top: 50px;
  vertical-align: bottom;
  padding-left: -4px;
}

h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  color: black;
  font-size: 1.5em;
}

tools {
  float: left;
  text-align: center;
  vertical-align: center;
  padding-top: 2px;
  width: 98px;
  height: 18px;
  background: #D8C8F4;
  border-radius: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  color: black;
  font-size: 0.75em;
}

tools1 {
  float: left;
  text-align: center;
  vertical-align: center;
  padding-top: 2px;
  width: 128px;
  height: 18px;
  background: #D8C8F4;
  border-radius: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  color: black;
  font-size: 0.75em;
}


.App-projects-space {
  position: relative; 
  float: left;
  width: 11px;
  height: 21px;
  background: #FFFFFF;
  border-radius: 20px;
  flex-direction: row;
  margin: 0 auto;
}

.App-projects-space-big {
  position: relative; 
  float: left;
  width: 120px;
  height: 21px;
  background: #FFFFFF;
  border-radius: 20px;
  flex-direction: row;
  margin: 0 auto;
}

.projects-tools {
  padding-top: 40px;
  float: left;
}

.projects-tools-0 {
  padding-top: 60px;
}

.projects-tools-1 {
  padding-top: 25px;
  float: left;
}

h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  color: black;
  font-size: 1.2em;
  text-align: center;
}

h5 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  color: black;
  font-size: 0.9em;
  text-align: left;
  padding-bottom: 0px;
  padding-left: 7px;
}

.refer-links-top-1 img {
  height: 20px;
  width: 20px;
  padding-left: 52px;
  padding-top: 20px;

}

.refer-links-top-2 img {
  height: 20px;
  width: 20px;
  padding-left: 188px;
  padding-top: 38px;
}

.refer-links-top-0 img {
  justify-content: center;
  position: relative;
  vertical-align: center;
  float: left;
  height: 20px;
  width: 20px;
  padding-left: 90px;
  padding-top: 38px;

}

.refer-links-bottom img {
  height: 20px;
  width: 20px;
  padding-left: 45px;
  padding-top: 5px;
}

.refer-links-top img {
  height: 20px;
  width: 20px;
  padding-left: 45px;
  padding-top: 10px;
}

/* ************************************************************************** */

/* Experience */

.App-experience-title {
  text-align: left;
  padding-left: 150px;
  padding-top: 900px;
  padding-bottom: 200px;
  height: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: relative;
}

.experience-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 25px;
  padding-bottom: 100px;
}

.card {
  border-radius: 15px;
  height: 200px;
  max-width: 307px;
  padding-right: 20px;
  width: fit-content;
  opacity: 1;
  flex-grow: 3;
  justify-content: space-between;
  background: black;
  overflow: hidden;
  background: #B9DCF2;  
  background: -webkit-linear-gradient(90deg, rgba(185,220,242,0.1), rgba(255,199,249,0.6));
  background: linear-gradient(90deg, #B9DCF2, #FFC7F9); 
}

.card img {
  border-radius: 15px;
  height: 198px;
  max-width: 325px;
  opacity: 1;
  position: relative;
  display: inline-block;
  object-fit: cover;
  border: 1px solid #000;
}

.card img:hover{
  width:100%;
  height:100%;
  position: absolute;
  border-radius: 15px;
  height: 200px;
  max-width: 333px;
  opacity: 0;
  z-index: 10;
}

.card-body {
  padding-left: 25px;
  padding-top: 25px;
  position: absolute;
}

.card-body:hover {
  padding-left: 25px;
  padding-top: 25px;
}

.card-actual-body {
  position: relative;
}

h8 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  color: black;
  font-size: 0.9em;
  text-align: left;
  display: block;
  padding-right: 200px;
}

time {
  display: block;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  color: black;
  font-size: 0.75em;
  text-align: left;
  padding-bottom: 7px;
  padding-top: 5px;
}

.hl {
  position: absolute;
  opacity: 0.2;
  width: 275px;
  height: 0px;
  border: 1px solid #000000;
}

h9 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  color: black;
  font-size: 0.78em;
  text-align: left;
  display: block;
  padding-top: 10px;
  max-width: 290px;
}

h10 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  color: black;
  font-size: 0.8em;
  text-align: left;
  display: block;
  padding-top: 8px;
  padding-right: 200px;
}

.item-space {
  width: 60px;
}
